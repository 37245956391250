import consumer from "../channels/consumer"

export class VideoRoomMessenger {
  constructor(roomName, opUuid, userType) {
    const self = this
    this.roomName = roomName
    this.opUuid = opUuid
    this.userType = userType
    this.callbacks = {}
    let channelName = 'VideoRoomMessageChannel'
    if (userType == 'participant') {
      channelName = 'VideoRoomParticipantMessageChannel'
    }
    this.channel = consumer.subscriptions.create({ channel: channelName, op: opUuid, room: this.roomName }, {
      connected() {
        self.dispatch('connected')
      },
      received(payload) {
        const eventName = self.userType + '.' + payload['event']
        const data = payload['data']
        self.dispatch(eventName, [data])
      }
    })
  }

  addEventListener(eventName, callback) {
    if (!this.callbacks[eventName]) {
      this.callbacks[eventName] = []
    }
    this.callbacks[eventName].push(callback)
  }

  dispatch(eventName, args = []) {
    if (!this.callbacks[eventName]) { return }
    this.callbacks[eventName].forEach(callback => {
      callback.apply(this, args)
    })
  }

  apply() {
    this.channel.perform('apply')
  }

  approve(pid) {
    this.channel.perform('approve', { participant_id: pid })
  }

  enter() {
    this.channel.perform('enter')
  }

  reject(pid) {
    this.channel.perform('reject', { participant_id: pid })
  }

  close() {
    this.channel.perform('close')
  }
}
