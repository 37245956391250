import { VideoRoom } from './video-room.js'
import { VideoRoomMessenger } from './video-room-messenger.js'
import { Modal } from 'mdb-ui-kit'
import { Toast } from './toast.js'

export default (function(){
  document.addEventListener('DOMContentLoaded', function(){
    const roomContainer = document.querySelector('[data-video-room]')
    if (!roomContainer) {
      return
    }

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const toast = new Toast()
    const roomName = roomContainer.dataset.videoRoom
    const opUuid = roomContainer.dataset.videoRoomOp
    const token = roomContainer.dataset.videoRoomToken
    const userType = roomContainer.dataset.videoRoomUserType
    const localMediaContainer = roomContainer.querySelector('[data-video-room-target="local-media"]')
    const remoteMediaContainer = roomContainer.querySelector('[data-video-room-target="remote-media"]')
    const btnConnect = roomContainer.querySelector('[data-video-room-target="connect"]')
    const btnApply = roomContainer.querySelector('[data-video-room-target="apply"]')
    const btnApprove = roomContainer.querySelector('[data-video-room-target="approve"]')
    const btnReject = roomContainer.querySelector('[data-video-room-target="reject"]')
    const btnDisconnect = roomContainer.querySelector('[data-video-room-target="disconnect"]')
    const btnSwap = roomContainer.querySelector('[data-video-room-target="camera-swap"]')
    const btnVideo = roomContainer.querySelector('[data-video-room-target="video-enable"]')
    const btnMute = roomContainer.querySelector('[data-video-room-target="mute"]')
    const btnClose = roomContainer.querySelector('[data-video-room-target="close"]')
    const modalApplyContainer = document.querySelector('#video-room-modal-apply')
    const modalApply = new Modal(modalApplyContainer)
    const modalApproveContainer = document.querySelector('#video-room-modal-approve')
    const modalApprove = new Modal(modalApproveContainer)

    const videoRoom = new VideoRoom(roomName, token)
    const messenger = new VideoRoomMessenger(roomName, opUuid, userType)
    messenger.addEventListener('participant.status_changed', data => {
      const ownerContainer = modalApplyContainer.querySelector('[data-video-room-target="owner"]')
      ownerContainer.innerHTML = data['owner']
      if (data['available']) {
        btnApply.classList.remove('is-applying', 'is-fullhouse')
        btnApply.disabled = false
      } else {
        btnApply.classList.remove('is-applying')
        btnApply.classList.add('is-fullhouse')
        btnApply.disabled = true
      }
    })
    messenger.addEventListener('owner.applied', data => {
      const orgContainer = modalApproveContainer.querySelector('[data-video-room-target="apply-organization"]')
      const userContainer = modalApproveContainer.querySelector('[data-video-room-target="apply-user"]')
      orgContainer.innerHTML = data['organization']['name']
      userContainer.innerHTML = data['participant']['name']
      btnApprove.dataset.participantId = data['participant_id']
      btnApprove.disabled = false
      btnReject.dataset.participantId = data['participant_id']
      btnReject.disabled = false
      modalApprove.show()
    })
    messenger.addEventListener('owner.canceled', data => {
      modalApprove.hide()
    })
    messenger.addEventListener('participant.approved', data => {
      modalApply.hide()
      videoRoom.connect()
    })
    messenger.addEventListener('participant.rejected', data => {
      alert(i18n.t('js.video_room.message.entry_denied'))
      window.close()
    })
    messenger.addEventListener('participant.closed', data => {
      videoRoom.disconnect()
      alert(i18n.t('js.video_room.message.room_has_closed'))
      window.close()
    })
    messenger.addEventListener('owner.empty', data => {
      alert(i18n.t('js.video_room.toast.left'))
      videoRoom.disconnect()
    })

    videoRoom.addEventListener('connected', () => {
      roomContainer.classList.remove('disconnected')
      roomContainer.classList.add('connected')
      messenger.enter()
    })
    videoRoom.addEventListener('disconnected', () => {
      roomContainer.classList.remove('connected')
      roomContainer.classList.add('disconnected')
      remoteMediaContainer.innerHTML = ''
      messenger.close()
      window.close()
    })
    videoRoom.addEventListener('readyLocalVideo', video => {
      if (video.enabled) {
        localMediaContainer.appendChild(video.track.attach())
      }
      btnSwap.disabled = !video.enabled
      btnVideo.disabled = !video.enabled
    })
    videoRoom.addEventListener('readyLocalAudio', audio => {
      btnMute.disabled = !audio.enabled
    })
    videoRoom.addEventListener('ready', () => {
      if (userType == 'participant') {
        modalApply.show()
      }
    })
    videoRoom.addEventListener('participantConnected', (track, identity) => {
      remoteMediaContainer.appendChild(track.attach())
    })
    videoRoom.addEventListener('participantDisconnected', () => {
      remoteMediaContainer.innerHTML = ''
    })
    videoRoom.addEventListener('errorCameraNotAllowed', () => {
      toast.simpleMessage(i18n.t('js.video_room.error.camera_permission'), 'warning', 'dark')
    })
    videoRoom.addEventListener('errorMicNotAllowed', () => {
      toast.simpleMessage(i18n.t('js.video_room.error.mic_permission'), 'warning', 'dark')
    })
    videoRoom.addEventListener('errorConnect', message => {
      toast.simpleMessage(`Connection error: ${message}`, 'danger', 'white')
    })
    if (btnConnect) {
      btnConnect.addEventListener('click', e => {
        videoRoom.connect()
      })
    }
    if (btnApply) {
      btnApply.addEventListener('click', e => {
        btnApply.disabled = true
        btnApply.classList.remove('is-fullhouse')
        btnApply.classList.add('is-applying')
        messenger.apply()
      })
    }
    if (btnApprove) {
      btnApprove.addEventListener('click', e => {
        const pid = btnApprove.dataset.participantId
        btnApprove.disabled = true
        messenger.approve(pid)
        videoRoom.connect()
        modalApprove.hide()
      })
    }
    if (btnReject) {
      btnReject.addEventListener('click', e => {
        const pid = btnReject.dataset.participantId
        btnReject.disabled = true
        messenger.reject(pid)
        modalApprove.hide()
      })
    }
    if (btnClose) {
      btnClose.addEventListener('click', e => {
        messenger.close()
        window.close()
      })
    }
    btnDisconnect.addEventListener('click', e => {
      const ans = confirm(i18n.t('js.video_room.message.confirm_leave'))
      if (ans) {
        videoRoom.disconnect()
      }
    })
    btnSwap.addEventListener('click', e => {
      videoRoom.localVideoTrack.swapDevice()
    })
    btnVideo.addEventListener('click', e => {
      if (videoRoom.localVideoTrack.enabled) {
        videoRoom.localVideoTrack.disable()
        btnVideo.classList.remove('btn-status-enabled')
        btnVideo.classList.add('btn-status-disabled')
      } else {
        videoRoom.localVideoTrack.enable()
        btnVideo.classList.remove('btn-status-disabled')
        btnVideo.classList.add('btn-status-enabled')
      }
    })
    btnMute.addEventListener('click', e => {
      if (videoRoom.localAudioTrack.enabled) {
        videoRoom.localAudioTrack.mute()
        btnMute.classList.remove('btn-status-enabled')
        btnMute.classList.add('btn-status-disabled')
      } else {
        videoRoom.localAudioTrack.unmute()
        btnMute.classList.remove('btn-status-disabled')
        btnMute.classList.add('btn-status-enabled')
      }
    })

    localMediaContainer.addEventListener('click', e => {
      if (videoRoom.connected()) {
        roomContainer.classList.add('cascaded')
      }
    })
    remoteMediaContainer.addEventListener('click', e => {
      if (videoRoom.connected()) {
        roomContainer.classList.remove('cascaded')
      }
    })

    videoRoom.prepare()
  })
})()
