import { Modal } from 'mdb-ui-kit';

class VisitsControlPanel {
  constructor() {
    document.addEventListener('DOMContentLoaded', function(e){
      this.panel = document.querySelector('.visit-control-panel');
      if(this.panel){
        this.initialize();
      }
    }.bind(this));
  }

  initialize() {
    this.reportingButton = this.panel.querySelector('[data-iy-visits-control="reporting"]');
    this.downloadButton = this.panel.querySelector('[data-iy-visits-control="reporting-download"]');
    this.archiveButton = this.panel.querySelector('[data-iy-visits-control="archive"]');
    this.archiveModal = new Modal(document.querySelector('#modal-archive-visits'));
    this.initPanel();
    this.initCheckboxHandler();
  }

  initPanel() {
    this.reportingButton.addEventListener('click', function(e){
      e = e || window.event;
      e.preventDefault();
      if (this.checkedVisitsToArchive()){
        this.archiveModal.show();
      }
    }.bind(this));

    this.downloadButton.addEventListener('click', function(e){
      e = e || window.event;
      e.preventDefault();
      if (this.checkedVisitsToArchive()){
        this.archiveModal.show();
      }
    }.bind(this));

    this.archiveButton.addEventListener('click', function(e){
      e = e || window.event;
      e.preventDefault();
      this.archiveModal.show();
    }.bind(this));
  }

  initCheckboxHandler() {
    document.addEventListener('change', function(e){
      e = e || window.event;
      var target = e.target || e.srcElement;
      var handler = target.closest('[data-show-panel-handler]');
      if(handler){
        this.refreshPanel();
      }
    }.bind(this));

    setTimeout(function(){
      this.refreshPanel();
    }.bind(this), 500);
  }

  refreshPanel() {
    if(this.checkedVisits()){
      this.refreshArchiveButton();
      this.panel.classList.remove('d-none');
    }else{
      this.panel.classList.add('d-none');
    }
  }

  refreshArchiveButton() {
    if(this.checkedVisitsToArchive()){
      this.archiveButton.classList.remove('disabled');
    }else{
      this.archiveButton.classList.add('disabled');
    }
  }

  checkedVisits() {
    return this._checkedVisits('[data-show-panel-value]');
  }

  checkedVisitsToArchive() {
    return this._checkedVisits('[data-show-panel-value][data-iy-visit-status="judged"]');
  }

  _checkedVisits(selector) {
    var checked = false;
    var handlers = document.querySelectorAll(selector);
    for(var i=0; i<handlers.length; i++){
      if(handlers[i].checked){
        checked = true;
        break;
      }
    }
    return checked;
  }
}

export default new VisitsControlPanel();
