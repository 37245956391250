export default (function(){
  document.addEventListener('DOMContentLoaded', function(e){
    var handlers = document.querySelectorAll('[data-form-hook]');
    for(var i=0; i<handlers.length; i++){
      handlers[i].addEventListener('click', function(e){
        var handler = this;
        var form = document.querySelector(handler.dataset.formHook);

        var hookUrl = handler.dataset.formHookUrl;
        var bkUrl = form.action;
        if(hookUrl){
          form.action = hookUrl;
        }

        var hookMethod = handler.dataset.formHookMethod;
        var bkMethod = form.method;
        if(hookMethod){
          form.method = hookMethod;
        }

        var hookTarget = handler.dataset.formHookTarget;
        var bkTarget = form.target;
        if(hookTarget){
          form.target = hookTarget;
        }

        form.submit();

        setTimeout(function(){
          form.action = bkUrl;
          form.method = bkMethod;
          form.target = bkTarget;
        }, 100);

        e = e || window.event;
        e.preventDefault();
      });
    }
  });
})();
