import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';

export default (function(){
  document.addEventListener('DOMContentLoaded', function(){
    const images = document.querySelectorAll('.viewerjs');
    for(var i=0; i<images.length; i++){
      var image = images[i];
      var viewer = new Viewer(image);
    }
  });
})();
