import MetisMenu from 'metismenujs';

export default (function(){
  document.addEventListener('DOMContentLoaded', function(){
    var sidemenuMode = document.body.dataset.sidemenu;
    var sidebarElement = document.querySelector('#sidebar-menu');
    if(sidebarElement){
      var sidebar = mdb.Offcanvas.getOrCreateInstance(sidebarElement);
      function toggleOffcanvas(){
        if(window.innerWidth < 768){
          offMenuMode();
        } else {
          onMenuMode();
        }
      }
      function offMenuMode(){
        document.body.classList.add('offmenu-mode');
        document.body.classList.remove('onmenu-mode');
        sidebarElement.style.visibility = 'hidden';
        if(sidebarElement.classList.contains('show')){
          sidebar.hide();
        }
      }
      function onMenuMode(){
        document.body.classList.remove('offmenu-mode');
        document.body.classList.add('onmenu-mode');
        if(!sidebarElement.classList.contains('show')){
          sidebar.show();
        }
      }

      if(sidemenuMode == 'on'){
        onMenuMode();
      }else if(sidemenuMode == 'off'){
        offMenuMode();
      }else{
        toggleOffcanvas();
        window.addEventListener('resize', function(){
          toggleOffcanvas();
        });
      }

      new MetisMenu('#sidemenu');
    }
  });
})();
