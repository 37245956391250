export default (function(){
  document.addEventListener('DOMContentLoaded', function(e){
    const handlers = document.querySelectorAll('[data-archive-visits]');
    if(handlers.length == 0){
      return;
    }

    for(var i=0; i<handlers.length; i++){
      const handler = handlers[i];
      const form = document.querySelector(handler.dataset.archiveVisitsForm);
      handler.addEventListener('click', function(e){
        form.querySelector('input[type=submit]').click();
        handler.disabled = true;
      });

      const subwindow = form.getAttribute('data-subwindow');
      if(subwindow){
        form.addEventListener('ajax:success', function(e){
          window.opener.location.reload();
          window.close();
        });
      }else{
        form.addEventListener('ajax:success', function(e){
          window.location.reload();
        });
      }
      form.addEventListener('ajax:error', function(e){
        const messages = e.detail[0].error_messages;
        alert("Failed archive\n" + messages.join("\n"))
        handler.disabled = false;
      });
    }
  });
})();
