export default (function(){
  var loadNextPage = function(){
    var nextLink = document.querySelector('#next_link');
    if(!nextLink) { return }
    if(nextLink.dataset.loading) { return }
    var table = document.querySelector('[data-auto-increment]');
    var wBottom = window.scrollY + window.innerHeight;
    var elBottom = table.offsetTop + table.offsetHeight;
    if(wBottom > elBottom){
      nextLink.click();
      nextLink.dataset.loading = true;
      nextLink.classList.add('spinner-border');
      nextLink.innerHTML = '';
    }
  }
  window.addEventListener('resize', loadNextPage);
  window.addEventListener('scroll', loadNextPage);
  window.addEventListener('load', loadNextPage);
})();
