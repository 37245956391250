export default (function(){
  document.addEventListener('click', function(e){
    e = e || window.event;
    var target = e.target || e.srcElement;
    var clickable_row = target.closest('.table-clickable-row');
    var is_checkbox = target.closest('.table-cell-checkbox');
    if(clickable_row && !is_checkbox){
      var href = clickable_row.dataset.href;
      if(href){
        window.location.href = href;
        e.preventDefault();
      }
    }
  });
})();
