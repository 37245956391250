export default (function(){
  function setTableHeaderOffset(){
    var offsetTop = 0;
    var pageHeader = document.querySelector('.topbar');
    if(pageHeader){
      offsetTop = pageHeader.offsetHeight - 1;
    }
    var tables = document.querySelectorAll('.table-sticky-header');
    for(var i=0; i<tables.length; i++){
      var table = tables[i];
      var header = table.querySelector('thead');
      var headerHeight = 0;
      if(header){
        headerHeight = header.offsetHeight;
      }
      var groupHeaderTop = offsetTop + headerHeight - 1;
      var groupHeaders = table.querySelectorAll('.group-title th');
      for(var j=0; j<groupHeaders.length; j++){
        var groupHeader = groupHeaders[j];
        groupHeader.style.top = groupHeaderTop + 'px';
      }
    }
  }
  document.addEventListener('DOMContentLoaded', setTableHeaderOffset);
  window.addEventListener('resize', setTableHeaderOffset);
})();
