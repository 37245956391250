export default (function(){
  document.addEventListener('DOMContentLoaded', function(){
    function expandTextarea(textarea){
      textarea.style.height = 0;
      textarea.style.height = textarea.scrollHeight + 4 + 'px';
    }
    const textareas = document.querySelectorAll('textarea.auto-expand');
    for(var i=0; i<textareas.length; i++){
      var textarea = textareas[i];
      textarea.addEventListener('keyup', function(){
        expandTextarea(this);
      }, false);
      expandTextarea(textarea);
    }
  });
})();
