import { Modal } from 'mdb-ui-kit';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

class EyeSnapper {
  constructor(){
    document.addEventListener('ajax:success', function(e){
      if(e.target.hasAttribute('data-iy-snapshot-remove')){
        const placeholder = e.target.closest('[data-snapper-placeholder]');
        const holdKey = placeholder.getAttribute('data-snapper-placeholder');
        const viewer = e.target.closest('[data-snapper-item-viewer]');
        viewer.remove();
        if(!placeholder.querySelector('[data-snapper-item-viewer]')){
          const na = placeholder.querySelector('[data-snapper-na]');
          na.classList.remove('d-none');
        }
      }
    });
  }

  initialize(){
    if(this.modal){
      return;
    }
    this.modal = document.getElementById('eye-snapper-dialog');
    if (this.modal) {
      this.initModal();
      this.initForm();
      this.initCanvas();
      this.dialog = new Modal(this.modal);
    }
  }

  initModal(){
    this.modal.addEventListener('hidden.bs.modal', function() {
      if(this.cropper){
        this.cropper.destroy();
      }
    }.bind(this));
  }

  initForm(){
    this.form = this.modal.getElementsByTagName('form')[0];
    this.image_field = this.form.querySelector('input[data-snapper-image-data]');
    this.video_id_field = this.form.querySelector('input[name="visit_snapshot[video_id]"]');
    this.errors = this.form.querySelector('.alert');
    this.form.addEventListener('submit', function(e){
      this.image_field.value = this.cropper.getCroppedCanvas().toDataURL();
    }.bind(this));
    this.form.addEventListener('ajax:success', function(event) {
      const [data, status, xhr] = event.detail;
      this.clearError();
      const hold_key = data.device + '--' + data.eye_side;
      const placeholder = document.querySelector('[data-snapper-placeholder="' + hold_key + '"]');
      if(placeholder){
        const formName = placeholder.getAttribute('data-iy-form-name');
        const viewer = document.createElement('div');
        viewer.classList.add('col-12', 'col-lg-10', 'col-xl-8');
        viewer.dataset.snapperItemViewer = data.id;
        viewer.innerHTML = '<input type="hidden" name="' + formName + '" value="' + data.id + '">' + data.content;
        const na = placeholder.querySelector('[data-snapper-na]');
        na.classList.add('d-none');
        placeholder.appendChild(viewer);
      };
      this.dialog.hide();
    }.bind(this));
    this.form.addEventListener('ajax:error', function(event) {
      const [data, status, xhr] = event.detail;
      var messages = data.messages;
      if(!messages){
        messages = ["An unexpected error has occurred."];
      }
      this.showError(messages);
    }.bind(this));
  }

  initCanvas(){
    this.canvas = this.modal.getElementsByClassName('snapper-canvas')[0];
    this.canvas.setAttribute('display', 'block');
    this.canvas.setAttribute('max-width', '100%');
  }

  show(source, video_id, device, eye_side){
    this.initialize();
    if (this.modal) {
      this.source = source;
      this.video_id = video_id;
      this.device = device;
      this.eye_side = eye_side;
      this.clearError();
      this.copyToCanvas();
      this.initCropper();
      this.setVideoId(video_id);
      this.setDefaultForm();
      this.dialog.show();
    }
  }

  copyToCanvas(){
    const maxWidth = 400;
    const sourceWidth = this.source.offsetWidth;
    const sourceHeight = this.source.offsetHeight;
    var width = document.documentElement.clientWidth * 0.8;
    if(width > maxWidth){
      width = maxWidth;
    }
    this.width = width;
    this.height = (sourceHeight / sourceWidth) * width;
    this.canvas.setAttribute('width', this.width);
    this.canvas.setAttribute('height', this.height);
    // this.source.crossOrigin = "anonymous";
    this.canvas.getContext('2d').drawImage(this.source, 0, 0, this.width, this.height);
  }

  initCropper(){
    this.cropper = new Cropper(this.canvas, {
      viewMode: 2,
      dragMode: 'crop',
      autoCropArea: 1,
      aspectRatio: 4 / 3,
      restore: true,
      guides: true,
      center: true,
      highlight: false,
      cropBoxMovable: true,
      cropBoxResizable: false,
      toggleDragModeOnDblclick: false,
      minContainerWidth: this.width,
      minContainerHeight: this.height,
    });
  }

  setVideoId(video_id){
    this.video_id_field.value = video_id;
  }

  setDefaultForm(){
    this.setRadioValue('.form-snapper-device input[type=radio]', this.device);
    this.setRadioValue('.form-snapper-eye_side input[type=radio]', this.eye_side);
  }

  setRadioValue(selector, defaultValue){
    const targetInputs = this.modal.querySelectorAll(selector);
    for(var i=0; i<targetInputs.length; i++){
      const input = targetInputs[i];
      input.checked = (input.value == defaultValue);
    }
  }

  clearError(){
    this.errors.classList.add('d-none');
    this.errors.innerHTML = '';
  }

  showError(messages){
    this.errors.innerHTML = messages.join("<br>");
    this.errors.classList.remove('d-none');
  }
}

export default new EyeSnapper();
