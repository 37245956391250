import { Modal } from 'mdb-ui-kit';

export default (function(){
  document.addEventListener('DOMContentLoaded', function(){
    const forms = document.querySelectorAll('[data-iy-judgement-confirm-form]');
    for(var i=0; i<forms.length; i++){
      const form = forms[i];
      form.addEventListener('submit', function(e){
        e = e || window.event;
        if(!form.submitted && wrapUpJudgement(form)){
          e.preventDefault();
          const dialogEl = document.getElementById('judgement-confirm');
          const dialog = new Modal(dialogEl);
          dialog.show();
          form.submitted = true;
          dialogEl.addEventListener('hide.bs.modal', function(){ form.submitted = false })
        }
      })
    }
  });

  function wrapUpJudgement(form) {
    var items = {};

    const dl = document.getElementById('judgement-confirm-irregulars');
    dl.classList.add('row')
    dl.classList.add('mb-0')
    dl.innerHTML = '';
    var irregularCount = 0;

    const irregularRadio = form.querySelectorAll('input[type=radio]:checked:not(.ignore-value)');
    for(var i=0; i<irregularRadio.length; i++){
      var radio = irregularRadio[i];
      irregularCount += 1;
      items[radio.dataset.iyIndex] = generateListItem(radio.dataset.iyEyeside, radio.dataset.iyLabel, radio.dataset.iyValue);
    }

    const textArea = form.querySelectorAll('textarea');
    for(var i=0; i<textArea.length; i++){
      var text = textArea[i];
      if(text.value != ''){
        irregularCount += 1;
        items[text.dataset.iyIndex] = generateListItem(text.dataset.iyEyeside, text.dataset.iyLabel, text.value);
      }
    }

    const targetSelects = form.querySelectorAll('select');
    for(var i=0; i<targetSelects.length; i++){
      var select = targetSelects[i];
      var values = select.querySelectorAll('option[selected]:not(.ignore-value)');
      if(values.length > 0){
        var texts = Array.from(values).map(v => v.text).join(', ');
        irregularCount += 1;
        items[select.dataset.iyIndex] = generateListItem(select.dataset.iyEyeside, select.dataset.iyLabel, texts);
      }
    }

    const shouldFills = form.querySelectorAll('input[data-iy-fill]');
    for(var i=0; i<shouldFills.length; i++){
      var input = shouldFills[i];
      if(input.value == ''){
        irregularCount += 1;
        const naText = input.getAttribute('data-iy-na');
        items[input.dataset.iyIndex] = generateListItem(input.dataset.iyEyeside, input.dataset.iyLabel, naText);
      }
    }

    const fillArrayElements = form.querySelectorAll('[data-iy-fill-array]:not([data-iy-ignore-value])');
    for(var i=0; i<fillArrayElements.length; i++){
      const fillArrayElement = fillArrayElements[i];
      if(!fillArrayElement.querySelector(fillArrayElement.getAttribute('data-iy-fill-array'))){
        irregularCount += 1;
        items[fillArrayElement.dataset.iyIndex] = generateListItem(fillArrayElement.dataset.iyEyeside, fillArrayElement.dataset.iyLabel, fillArrayElement.dataset.iyNa);
      }
    }

    var indeces = Object.keys(items);
    indeces.sort();
    for(var index of indeces){
      dl.appendChild(items[index][0]);
      dl.appendChild(items[index][1]);
    }

    return irregularCount > 0;
  }

  function generateListItem(eye_side, label, value) {
    var dt = document.createElement('dt');
    var dd = document.createElement('dd');
    dt.classList.add('col-sm-4');
    dd.classList.add('col-sm-8');
    dd.classList.add('mb-2');
    if(eye_side == 'left' || eye_side == 'right'){
      var eyeSideBadge = document.createElement('span');
      eyeSideBadge.innerText = i18n.t('dict.' + eye_side + '_eye');
      eyeSideBadge.classList.add('badge');
      eyeSideBadge.classList.add('me-1');
      eyeSideBadge.classList.add('bg-' + eye_side + '-eye');
      dt.appendChild(eyeSideBadge);
    }
    if(label){
      var labelElem = document.createElement('span');
      labelElem.innerText = label + ':'
      labelElem.classList.add('me-1')
      dt.appendChild(labelElem);
    }
    if(value){
      var valueElem = document.createElement('span');
      valueElem.innerText = value
      dd.appendChild(valueElem);
    }
    return [dt, dd];
  }
})();
