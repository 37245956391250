import videojs from 'video.js';

export default (function(){
  document.addEventListener('DOMContentLoaded', function(){
    window.reloading = false;
    var videos = document.querySelectorAll('.video-js');
    for(var i=0; i<videos.length; i++){
      const video = videos[i];
      const video_id = video.id;
      const player = videojs(video_id);
      player.on('error', function(){
        console.log(this);
        var error = this.error();
        console.log(error);
        if(!window.reloading && error.code == MediaError.MEDIA_ERR_NETWORK){
          var ans = confirm(i18n.t('js.video.expired_error'))
          if(ans){
            location.reload();
            window.reloading = true;
          }
        }
      });
      player.on('canplaythrough', function(){
        const targets = document.querySelectorAll('[data-videojs-wait-ready="' + video_id + '"]');
        for(var i=0; i<targets.length; i++){
          const target = targets[i];
          target.classList.remove('disabled');
        }
      });
    }

    var videoBulkPlayBtns = document.querySelectorAll('[data-video-bulk-play]');
    for(var i=0; i<videoBulkPlayBtns.length; i++){
      var btn = videoBulkPlayBtns[i];
      btn.addEventListener('click', function(e){
        var videoIds = this.dataset.videoBulkPlay.split(',');
        for(var j=0; j<videoIds.length; j++){
          var player = videojs(videoIds[j]);
          player.play();
        }
        e = e || window.event;
        e.preventDefault();
      });
    }
  });
})();
