import EyeSnapper from './eye-snapper.js';

export default (function(){
  document.addEventListener('DOMContentLoaded', function(){
    const snapperTriggers = document.querySelectorAll('[data-snapper-target]');
    if(snapperTriggers.length == 0){
      return;
    }

    for(var i=0; i<snapperTriggers.length; i++){
      const trigger = snapperTriggers[i];
      trigger.addEventListener('click', function(e){
        var source = document.querySelector(trigger.dataset.snapperTarget);
        const video_id = source.getAttribute('data-video-id');
        const device = source.getAttribute('data-device');
        const eye_side = source.getAttribute('data-eye-side');
        if(source.classList.contains('vjs-tech')){
          const poster = source.parentNode.querySelector('.vjs-poster');
          const posterSelector = source.getAttribute('data-poster-selector');
          if(posterSelector && !source.closest('.vjs-has-started')){
            source = document.querySelector(posterSelector);
            source.style.display = 'block';
          }
        }
        EyeSnapper.show(source, video_id, device, eye_side);
        e.preventDefault();
      });
    }
  });
})();
