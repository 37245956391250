export default (function(){
  document.addEventListener('click', function(e){
    e = e || window.event;
    var target = e.target || e.srcElement;
    var handler = target.closest('[data-group-check-handler]');
    if(handler){
      var checked = handler.checked;
      var selector = handler.dataset.groupCheckHandler;
      var checkboxes = document.querySelectorAll(selector);
      for(var i=0; i<checkboxes.length; i++){
        checkboxes[i].checked = checked;
      }
    }
  });
})();
