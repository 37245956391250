import { Modal } from 'mdb-ui-kit';

export default (function(){
  document.addEventListener('DOMContentLoaded', function(e){
    var printHandler = document.querySelector('[data-print-visit]');
    if(!printHandler){
      return;
    }

    printHandler.addEventListener('click', function(e){
      window.print();
      e = e || window.event;
      e.preventDefault();
    });

    window.addEventListener('afterprint', function(e){
      var modal = document.getElementById('modal-archive-visits');
      if (modal) {
        var dialog = new Modal(modal);
        dialog.show();
      }
    });
  });
})();
