export default (function(){
  document.addEventListener('DOMContentLoaded', function(){
    var hamburgers = document.querySelectorAll('.hamburger-animate');
    hamburgers.forEach(function(hamburger){
      hamburger.addEventListener('click', function(){
        hamburger.classList.toggle('is-active');
      });
    });
  });
})();
