export class VideoRoom {
  constructor(roomName, token) {
    const self = this

    this.verbose = true
    this.roomName = roomName
    this.token = token
    this.ready = false
    this.callbacks = {}
    this.room = null

    this.localVideoTrack = {
      ready: false,
      enabled: false,
      mode: 'user',
      track: null,
      prepare: function(mode = 'user') {
        const { createLocalVideoTrack } = require('twilio-video')
        this.mode = mode
        createLocalVideoTrack({ facingMode: mode }).then(track => {
          this.track = track
          this.ready = true
          this.enabled = true
          self.dispatch('readyLocalVideo', [this])
          self.dispatchReady()
        }, error => {
          this.ready = true
          this.enabled = false
          self.dispatch('readyLocalVideo', [this])
          self.dispatchReady()
          if (error.name == 'NotAllowedError') {
            self.dispatch('errorCameraNotAllowed')
          } else {
            self.error(`Local connection error: ${error.message}: ${error.name}`, error)
          }
        })
      },
      enable: function() {
        this.track.enable()
        this.enabled = true
      },
      disable: function() {
        this.track.disable()
        this.enabled = false
      },
      switchDevice: function(mode) {
        this.mode = mode
        this.track.restart({ facingMode: mode })
      },
      swapDevice: function() {
        let mode = 'user'
        if (this.mode == 'user') {
          mode = 'environment'
        }
        this.switchDevice(mode)
      }
    }

    this.localAudioTrack = {
      ready: false,
      enabled: false,
      track: null,
      prepare: function() {
        const { createLocalAudioTrack } = require('twilio-video')
        createLocalAudioTrack().then(track => {
          this.track = track
          this.ready = true
          this.enabled = true
          self.dispatch('readyLocalAudio', [this])
          self.dispatchReady()
        }, error => {
          this.ready = true
          this.enabled = false
          self.dispatch('readyLocalAudio', [this])
          self.dispatchReady()
          if (error.name == 'NotAllowedError') {
            self.dispatch('errorMicNotAllowed')
          } else {
            self.error(`Local connection error: ${error.message}: ${error.name}`, error)
          }
        })
      },
      unmute: function() {
        this.track.enable()
        this.enabled = true
      },
      mute: function() {
        this.track.disable()
        this.enabled = false
      },
      toggleMute: function() {
        if (this.enabled) {
          this.mute()
        } else {
          this.unmute()
        }
      }
    }
  }

  addEventListener(eventName, callback) {
    if (!this.callbacks[eventName]) {
      this.callbacks[eventName] = []
    }
    this.callbacks[eventName].push(callback)
  }

  dispatch(eventName, args = []) {
    if (!this.callbacks[eventName]) { return }
    this.callbacks[eventName].forEach(callback => {
      callback.apply(this, args)
    })
  }

  dispatchReady() {
    this.ready = (this.localVideoTrack.ready && this.localAudioTrack.ready)
    if (this.ready) {
      this.dispatch('ready')
    }
  }

  prepare() {
    this.localVideoTrack.prepare()
    this.localAudioTrack.prepare()
  }

  connected() {
    return (this.room !== null)
  }

  connect() {
    if (this.connected()) { return }

    const { connect } = require('twilio-video')
    let tracks = []
    if (this.localVideoTrack.enabled) { tracks.push(this.localVideoTrack.track) }
    if (this.localAudioTrack.enabled) { tracks.push(this.localAudioTrack.track) }
    connect(this.token, { name: this.roomName, tracks: tracks }).then(room => {
      this.log(`Connected to Room: ${room.name}`)

      const localParticipant = room.localParticipant
      this.log(`Connected to the Room as LocalPafticipant "${localParticipant.identity}"`)
      room.participants.forEach(participant => {
        this.log(`Participant "${participant.identity}" is connected to the Room`)
        this.appendParticipant(participant)
      })

      room.on('participantConnected', participant => {
        this.log(`Participant "${participant.identity}" has connected to the Room`)
        this.appendParticipant(participant)
      })

      room.on('participantDisconnected', participant => {
        this.log(`Participant "${participant.identity}" has disconnected from Room`)
        this.removeParticipant(participant)
      })

      room.on('disconnected', room => {
        this.dispatch('disconnected')
      })

      this.room = room
      this.dispatch('connected')
    }, error => {
      this.dispatch('errorConnect', [error.message])
      this.error(`Unable to connect to Room: ${error.message}`, error)
    })
  }

  disconnect() {
    if (!this.connected()) { return }

    this.room.disconnect()
    this.room = null
  }

  appendParticipant(participant) {
    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        this.dispatch('participantConnected', [publication.track, participant.identity])
      }
    })
    participant.on('trackSubscribed', track => {
      this.dispatch('participantConnected', [track, participant.identity])
    })
  }

  removeParticipant(participant) {
    this.dispatch('participantDisconnected', participant)
  }

  log(message) {
    if (this.verbose) {
      console.log(message)
    }
  }

  error(message, error) {
    if (this.verbose) {
      console.error(message)
    }
    if (window.Rollbar) {
      window.Rollbar.error(message, error)
    }
  }
}
