export class Toast {
  constructor(container = null) {
    if (!container) {
      container = document.getElementById('toast-container')
    }
    if (!container) {
      container = document.createElement('div')
      container.id = 'toast-container'
      container.classList.add('toast-container', 'position-fixed', 'top-0', 'end-0', 'p-2')
      container.style.zIndex = 3000
      document.body.appendChild(container)
    }
    this.container = container
  }

  simpleMessage(message, type = 'primary', text = 'white') {
    const textMessage = document.createTextNode(message)
    const toastContainer = document.createElement('div')
    toastContainer.classList.add('toast', 'text-' + text, 'bg-' + type, 'border-0')
    const flexContainer = document.createElement('div')
    flexContainer.classList.add('d-flex')
    toastContainer.appendChild(flexContainer)
    const bodyContainer = document.createElement('div')
    bodyContainer.classList.add('toast-body')
    bodyContainer.appendChild(textMessage)
    flexContainer.appendChild(bodyContainer)
    const btnClose = document.createElement('button')
    btnClose.classList.add('btn-close', 'btn-close-' + text, 'me-2', 'm-auto')
    btnClose.dataset.mdbDismiss = 'toast'
    btnClose.setAttribute('type', 'button')
    flexContainer.appendChild(btnClose)
    toastContainer.classList.add('show')
    this.container.appendChild(toastContainer)
  }
}
