import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import * as mdb from 'mdb-ui-kit';
import { Input } from 'mdb-ui-kit';

import 'boxicons';

import Rollbar from 'rollbar';
window.Rollbar = new Rollbar({
  accessToken: 'be8884634c784eb68ce579a68a77c6a2'
});

import { I18n } from "i18n-js";
import translations from "./i18n-translations.json";
window.i18n = new I18n(translations);

import '../stylesheets/style.scss';

import 'simplebar';
import 'simplebar/dist/simplebar.css';

import 'jquery';
import 'daterangepicker';

import '../images/index.js.erb';
import '../javascripts/index.js.erb';

Rails.start()
ActiveStorage.start()

// console.log('Loaded Application');
