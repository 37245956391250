import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';
import EyeSnapper from './eye-snapper.js';

export default (function(){
  document.addEventListener('DOMContentLoaded', function(){
    function setFramesToHandler(handler, viewer) {
      handler.addEventListener('click', function(e){
        viewer.show();
        e = e || window.event;
        e.preventDefault();
        return false;
      });
    }

    const framesHandlers = document.querySelectorAll('[data-fv-target]');

    for(var i=0; i<framesHandlers.length; i++){
      const handler = framesHandlers[i];
      const frames = document.querySelector(handler.dataset.fvTarget);
      if(handler.hasAttribute('data-fv-snapshotable')){
        const device = handler.getAttribute('data-device');
        const eye_side = handler.getAttribute('data-eye-side');
        const viewer = new Viewer(frames, {
          toolbar: {
            zoomIn: true,
            zoomOut: true,
            oneToOne: true,
            reset: true,
            prev: true,
            play: true,
            next: true,
            rotateLeft: true,
            rotateRight: true,
            flipHorizontal: true,
            flipVertical: true,
            snapper: function(){
              EyeSnapper.show(viewer.image, device, eye_side);
              viewer.hide();
            }
          }
        });
        setFramesToHandler(handler, viewer);
      } else {
        const viewer = new Viewer(frames, {
          toolbar: {
            zoomIn: true,
            zoomOut: true,
            oneToOne: true,
            reset: true,
            prev: true,
            play: true,
            next: true,
            rotateLeft: true,
            rotateRight: true,
            flipHorizontal: true,
            flipVertical: true,
          }
        });
        setFramesToHandler(handler, viewer);
      }
    }
  });
})();
