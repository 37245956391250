import Tags from 'bootstrap5-tags/tags.js';

export default (function(){
  document.addEventListener('DOMContentLoaded', function(){
    Tags.init('.tags-input');
    const tags = Array.from(document.querySelectorAll('.tags-input'));
    tags.map(function(tagEl){
      const tag = Tags.getInstance(tagEl);
      const input = tag._searchInput;
      input.addEventListener('keyup', function(){
        if(tag.isInvalid()){
          const message = tagEl.dataset.iyTagsInvalidMessage;
          input.setCustomValidity(message);
        }else{
          input.setCustomValidity('');
        }
      });
    });
  });
})();
